:root {
  // Colors
  --baseWhite: #{$baseWhite};
  --baseBlack: #{$baseBlack};
  --baseText: #{$baseBlack};

  // Sizes
  --header-height: #{px(51)};
  --scrollbar-current-width-y: 0px;
  --scrollbar-static-width-y: 0px;
}
