.icon {
  transition: all 0.3s ease;

  &-arrow-select {
    width: px(10);
    min-width: px(10);
    height: px(10);
  }

  &-user,
  &-password,
  &-email {
    width: px(20);
    min-width: px(20);
    height: px(20);
  }
}
