.do {
  padding-top: 203px;
  padding-bottom: 153px;
  background: #1b1818;
  @include md {
    padding-top: 104px;
    padding-bottom: 104px;
  }
  &__container {
    max-width: 1950px;
    padding: 0 15px;
    margin: 0 auto;
  }

  &__more {
    background: transparent;
    color: #1b1818;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    width: 100%;

    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    padding: 0;
    transition: all 0.4s;

    &:hover {
      background-color: #1b1818;
      color: #fff;
    }
  }
  // .do__big-container

  &__title-bg {
    top: -40.6%;
    left: -14%;
    z-index: -1;
    @media (max-width: 1024px) {
      top: -80px;
      font-size: 132px;
      left: 2%;
    }
    @include xs {
      left: 2%;
      top: -17%;
      font-size: 70px;
    }
  }
  &__image {
    img {
      @media (max-width: 1440px) {
        max-width: 100%;
      }
    }
  }

  // .do__container

  &__container {
    width: 100%;
    position: relative;
    z-index: 2;
  }

  // .do__body

  &__body {
    display: flex;
    flex-direction: column;
  }

  // .do__top

  &__top {
    display: flex;
    flex-direction: column;
    gap: 109px;
  }

  // .do__top-title

  &__top-title {
    margin-bottom: 51px;
    position: relative;
    z-index: 2;
  }

  // .do__title

  &__title {
  }

  // .do__top-blocks

  &__top-blocks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: start;
      gap: 24px;
    }
  }

  // .do__left

  &__left {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 60px */
    text-transform: uppercase;
    max-width: 618px;

    @include sm {
      font-size: 24px;
    }
    span {
      color: #72c2ff;
    }
  }

  // .do__right

  &__right {
    font-weight: 400;
    line-height: 150%; /* 30px */
    max-width: 619px;
    @include xs {
      display: none;
    }
  }

  // .do__bottom

  &__top-bottom {
    padding: 0 15px;
    max-width: 1920px;
    display: flex;
    gap: 24px;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 1440px) {
      max-width: unset;
      width: 100%;
    }
    @include md {
      display: none;
    }
  }

  // .do__swiper

  &__swiper {
  }

  // .do__card

  &__card {
    padding: 24px 48px;
    background-color: transparent;
    width: 100%;
    border-radius: 9px;
    border: 1px solid #fff;
    transition: all 0.4s;
    cursor: pointer;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    min-height: 136px;
    transition: all 0.4s;
    gap: 0;
    max-width: 490px;
    @include md {
      max-width: unset;
      min-height: 50px;
    }
    @include sm {
      padding: 15px;
    }

    &:hover {
      @media (min-width: 991px) {
        background-color: #fff;
        gap: 24px;
        .do__card-top {
          color: #1b1818;
          &::after {
            background-color: #1b1818;
          }
        }
        .do__card-bottom {
          overflow: visible;
          visibility: visible;
          opacity: 1;
          max-height: 500px;
          margin-bottom: 24px;
        }
        .do__more {
          border: 1px solid #1b1818;
          padding: 19px;
          overflow: visible;
          opacity: 1;
          visibility: visible;
          max-height: 200px;
        }
      }
    }
  }

  // .do__card-top

  &__card-top {
    color: #fff;
    font-family: Unbounded;
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 43.5px */
    text-transform: uppercase;
    position: relative;
    padding-left: 67px;
    transition: all 0.4s;
    max-width: 365px;
    @media (max-width: 1440px) {
      font-size: 23px;
    }
    @media (max-width: 1360px) {
      font-size: 18px;
    }
    @include sm {
      font-size: 18px;
    }
    @include md {
      max-width: unset;
      width: 100%;
    }
    &::after {
      content: '';
      width: 51px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 50%;
    }
  }

  // .do__card-bottom

  &__card-bottom {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    transition: all 0.4s;
    max-width: 395px;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
    color: #1b1818;
    margin-bottom: 0px;
    @media (max-width: 1360px) {
      font-size: 19px;
    }
    @include sm {
      max-width: unset;
      padding: 0 10px;
    }
    @include xs {
      font-size: 16px;
    }
  }

  // .do__bottom

  &__bottom {
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;
    padding-bottom: 0;
    @media (max-width: 1550px) {
      display: block;
    }
    @include md {
      display: none;
    }
  }

  // .do__bottom-left

  &__bottom-left {
    padding-top: 70px;
    img {
      max-width: 1034px;
      width: 100%;
    }
  }

  // .do__bottom-right

  &__bottom-right {
    display: flex;
    align-items: center;
    flex-direction: column;
    right: 150px;
    position: relative;
    @include md {
      width: 100%;
    }
  }

  // .do__overview

  &__overview {
    border-radius: 24px;
    background: rgba(27, 24, 24, 0.39);
    backdrop-filter: blur(50px);
    padding: 64px 48px;
    z-index: 10;
    border: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 136px;
    @media (max-width: 1550px) {
      width: 100%;
    }
    @include md {
      margin-bottom: 0;
    }
    @include sm {
      padding: 24px;
    }
  }

  // .do__overview-list

  &__overview-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 50px 0;
    @include xl {
      padding: unset;
      padding-bottom: 50px;
    }
    @include md {
      padding-bottom: 20px;
    }
  }
  &__wrapper-bottom {
    display: flex;
  }

  // .do__overview-item

  &__overview-item {
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: baseline;
    gap: 16px;
    max-width: 650px;
    @include sm {
      max-width: unset;
    }
    svg {
      flex-basis: 13px;
    }
  }

  // .do__overview-flex

  &__overview-flex {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  // .do__overview-title

  &__overview-title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 150%; /* 36px */
    @include sm {
      font-size: 20px;
    }
    @include xs {
      max-width: 250px;
    }
  }

  &__swiper-slide {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;

    &._active {
      max-height: 2000px;
      opacity: 1;
      overflow: visible;
      visibility: visible;
      margin-top: 56px;
      margin-bottom: 200px;
      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
  }

  // .do__overview-subtitle

  &__overview-subtitle {
    max-width: 619px;
    @media (max-width: 400px) {
      max-width: 279px;
    }
  }

  // .do__contact

  &__contact {
    display: flex;
    margin-left: auto;
    width: fit-content;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    @media (max-width: 1550px) {
      margin: 0 auto;
    }
    @include xs {
      gap: 8px;
    }
  }

  &__card-bottom {
  }

  // .do__btn

  &__btn {
    width: 362px;
    padding: 18px 91px;
    position: relative;
    &::after {
      content: '';
      height: 56px;
      width: 1px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      top: -150%;
      @include xs {
        height: 30px;
        top: -100%;
      }
    }
    @include xs {
      width: 223px;
      padding: 12px 40px;
    }
  }

  // .do__contact-subtitle

  &__contact-subtitle {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
  }
}

.mobile-swiper {
  &__wrapper {
    @include md {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

.do__bottom-mobile {
  display: none;
  @media (max-width: 991px) {
    display: block;
    margin-top: 35px;
  }
}

.do {
  &__card-pc {
    &._active {
      background-color: #fff;
      gap: 24px;
      .do__card-top {
        color: #1b1818;
        &::after {
          background-color: #1b1818;
        }
      }
      .do__card-bottom {
        overflow: visible;
        visibility: visible;
        opacity: 1;
        max-height: 500px;
        margin-bottom: 24px;
      }
      .do__more {
        border: 1px solid #1b1818;
        padding: 19px;
        overflow: visible;
        opacity: 1;
        visibility: visible;
        max-height: 200px;
      }
    }
  }
  @media (max-width: 991px) {
    &__overview-text {
      margin-top: 24px;
    }
    &__overview {
      opacity: 0;
      max-height: 0;
      padding: 0;
      pointer-events: none;
      visibility: hidden;
      overflow: hidden;
      opacity: 0;
      margin-bottom: 0px;
      transition: all 0.4s;
      &._active {
        opacity: 1;
        max-height: 1700px;
        pointer-events: unset;
        visibility: visible;
        overflow: visible;
        opacity: 1;
        padding: 24px;
        margin-bottom: 24px;
      }
    }
    &__card {
      &._active {
        background-color: #fff;
        border-radius: 9px;

        .do__card-top {
          color: #1b1818;
          &::after {
            background-color: #1b1818;
          }
        }
        svg {
          transform: rotate(180deg);
          path {
            stroke: #1b1818;
          }
        }
        .do__card-bottom {
          overflow: visible;
          visibility: visible;
          opacity: 1;
          max-height: 1000px;
          color: #1b1818;
          @include xs {
            max-height: 300px;
          }
        }
      }
    }
  }

  &__card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 18px;
      height: 18px;
      transition: all 0.4s;
    }
  }
  &__mobile-cards {
    display: block;
    flex-direction: column;
    gap: 50px;
    padding: 0 15px;
    margin-bottom: 100px;
    @include xs {
      margin-bottom: 50px;
    }
  }
  &__mobile-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__card-top-text {
    @include xs {
      max-width: 164px;
    }
  }
}

.show-item {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 24px;
  position: relative;
  &--second {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 100px;
  }
  &--third {
    flex-direction: row-reverse;
  }
  &__left {
    width: 746px;
    position: relative;
    top: 45px;
    @media (max-width: 1440px) {
      width: 570px;
    }

    &--second {
      bottom: 200px;
      top: unset;
    }
    &::after {
      content: '';
      height: 56px;
      width: 1px;
      background-color: #fff;
      position: absolute;
      left: 51%;
      top: -75px;
    }
  }

  // .show-item-item__left

  &__left-wrapper-text {
    background: rgba(27, 24, 24, 0.39);
    backdrop-filter: blur(50px);
    padding: 64px 48px;
    position: relative;
    z-index: 20;
    border-radius: 24px;
    border: 1px solid gray;
    @media (max-width: 1440px) {
      padding: 40px;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    position: relative;
    top: 136px;
  }

  // .show-item-item__left-list

  &__left-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  // .show-item-item__list-item

  &__list-item {
    display: flex;
    gap: 16px;
    align-items: baseline;
  }

  // .show-item-item__list-title

  &__list-title {
    display: flex;
    align-items: center;
    gap: 24px;
    cursor: pointer;
    color: #fff;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
    @media (max-width: 1440px) {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      justify-content: space-between;
    }
  }

  // .show-item-item__list-subtitle

  &__list-subtitle {
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    max-width: 650px;

    opacity: 0;
    overflow: hidden;
    max-height: 0;
    pointer-events: none;
    transition: all 0.4s;
    @media (max-width: 1440px) {
      font-size: 16px;
    }
    &.show_active {
      opacity: 1;
      overflow: visible;
      max-height: 500px;
      pointer-events: unset;
    }
  }

  // .show-item-item__right

  &__right {
    position: relative;
    z-index: 2;
    right: 100px;
    @media (max-width: 1440px) {
      right: 40px;
    }
    &--third {
      left: 100px;
      right: 0;
      @media (max-width: 1440px) {
        left: 40px;
      }
    }
    &--second {
      right: 0;
      &::after {
        content: '';
        height: 56px;
        width: 1px;
        background-color: #fff;
        position: absolute;
        left: 51%;
        top: -13%;
      }
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &._active {
      .icon-arrow-down {
        transform: rotate(-180deg);
      }
    }
  }
}

.icon-arrow-down {
  width: 23px;
  height: 12px;
  stroke-width: 3px;
  stroke: #fff;
  fill: none;
}
