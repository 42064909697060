.field {
  width: 100%;

  &.invalid {
    .radio-group {
      &__title {
        color: red;
      }

      &__item-name {
        color: red;
      }
    }

    .field {
      &__textarea-container,
      &__input-container {
        .icon {
          fill: red;
        }
      }

      &__textarea,
      &__input {
        border-color: red;
      }

      &__error {
        display: block;
      }
    }
  }

  &.valid {
    .field {
      &__textarea-container,
      &__input-container {
        .icon {
          fill: green;
        }
      }

      &__textarea,
      &__input {
        border-color: green;
        color: green;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: block;
    margin-bottom: px(5);
  }

  &__optional {
    display: block;
    margin-left: px(10);
    color: grey;
  }

  &__textarea-container,
  &__input-container {
    width: 100%;
    position: relative;
    transition: all .3s ease;
  }

  &__input-container {
    width: 100%;
    margin-bottom: px(5);
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: px(10);
      transform: translateY(-50%);
    }

    .icon + .field__input {
      padding-left: px(35);
    }
  }

  &__textarea-container {
    .icon {
      position: absolute;
      top: px(10);
      left: px(10);
    }

    .icon + .field__textarea {
      padding-left: px(35);
    }
  }

  &__textarea,
  &__input {
    width: 100%;
    padding: px(10) px(20);
    text-align: start;
    border: px(1) solid #000;
  }

  &__input {
    min-height: px(43);

    &::placeholder {
      color: grey;
    }
  }

  &__textarea {
    min-height: px(150);
  }

  &__error,
  &__message {
    display: none;
    margin-top: px(5);
    width: 100%;
  }

  &__error {
    color: red;
  }
}
