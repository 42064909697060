.select {
  width: fit-content;
  min-width: px(150);
  max-width: px(200);
  position: relative;
  margin: 0 auto;

  &.open-top {
    .select__dropdown {
      top: unset;
      bottom: calc(100% + px(10));
      transform: translateY(px(30));
    }
  }

  &.active {
    z-index: 100;

    .select__trigger {
      .icon-arrow-select {
        transform: rotate(-180deg);
      }
    }

    .select__dropdown {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: translateY(0);
    }
  }

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: px(5) px(15);
    text-align: start;
    position: relative;
    z-index: 3;
    border: px(1) solid #000;
    background-color: var(--baseWhite);
    cursor: pointer;
    transition: all .3s ease;

    &:focus-within {
      box-shadow: 0 0 0 px(5) rgba($baseBlack, .2);
    }

    &-value {
      display: block;
      width: 100%;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .icon {
      margin-left: px(10);
    }
  }

  &__dropdown {
    width: 100%;
    height: px(150);
    position: absolute;
    top: calc(100% + px(10));
    left: 0;
    z-index: 2;
    background-color: var(--baseWhite);
    border: px(1) solid #000;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(px(-30));
  }

  &__list {
    max-height: 100%;
    overflow-y: auto;
  }

  &__link,
  &__btn {
    display: block;
    width: 100%;
    padding: px(4) px(10);
    text-align: start;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: var(--baseBlack);
      color: var(--baseWhite);
    }
  }
}
