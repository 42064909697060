.main-button {
  background: #fff;
  color: #1b1818;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  transition: all 0.4s !important;

  &:hover {
    background: #5bbaff;
    color: #1b1818;
  }
}

.blue-button {
  background: #72c2ff;
  color: #1b1818;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  &:hover {
    background: #fff;
    color: #1b1818;
  }
  @include xs {
    font-size: 16px;
  }
}

.title {
  color: #fff;
  font-family: Unbounded;
  font-size: 106px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
  &__color-span {
    color: #72c2ff;
  }
  &__bg-span {
    background-color: #72c2ff;
  }
  @media (max-width: 1440px) {
    font-size: 40px;
  }
  @include md {
    font-size: 70px;
  }
  @include sm {
    font-size: 56px;
  }
  @include xs {
    font-size: 36px;
  }
}

.title-bg {
  font-family: Unbounded;
  font-style: normal;
  font-weight: 400;
  font-size: 244px;
  text-shadow: 1px 1px 0 #ffff, -1px -1px 0 #ffff, 1px -1px 0 #ffff, -1px 1px 0 #ffff; /* Тень с разными смещениями и цветом */
  color: #1b1818; /* Цвет текста */
  white-space: nowrap;
  text-transform: uppercase;
  opacity: 0.2 !important;
  position: absolute;
  @media (max-width: 1440px) {
    font-size: 132px;
  }
  @include md {
    font-size: 100px;
  }
  @include sm {
    font-size: 80px;
  }
  @include xs {
    font-size: 70px;
  }
}
