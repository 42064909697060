.privacy {
  padding-top: 180px;
  padding-bottom: 180px;
  background: #1b1818;
  @include md {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
.privacy {
  // .privacy__container

  &__container {
  }

  // .privacy__body

  &__body {
  }
  &__email {
    color: #fff;
  }

  // .privacy__title

  &__title {
    margin-bottom: 50px;
  }

  &__text {
    h1 {
      margin: 30px 0 !important;
    }
    p {
      font-size: 20px;
      @include xs {
        font-size: 14px;
      }
    }
    li {
      font-size: 20px;
      margin-bottom: 30px !important;
      @include xs {
        font-size: 14px;
      }
    }
    *:not(:last-child) {
      margin-bottom: 20px;
    }
    ul {
      li {
        list-style: inside disc;
        &::marker {
          color: #72c2ff;
        }
      }
    }
  }
}
