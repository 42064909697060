.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background-color: rgba($baseBlack, 0.5);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  &__dialog {
    max-width: 1090px;
    width: 100%;
    position: relative;
    background-color: $baseWhite;
    min-height: 334px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include md {
      max-width: 500px;
    }
    @include xs {
      height: 100%;
      max-width: unset;
      width: 100%;
    }
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
  &__error-header-title {
    color: #1b1818;
    font-family: Unbounded;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 60px */
    text-transform: uppercase;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: px(30) px(20) px(10);
    justify-content: center;
  }

  &__header-close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: px(30);
    min-width: px(30);
    height: px(30);
  }

  &__body {
    padding: px(10) px(20) px(30);
  }

  &__header-title {
    color: #1b1818;
    font-family: Unbounded;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 60px */
    text-transform: uppercase;
    text-align: center;
    background-color: #72c2ff;
  }
  &__body-content {
    color: #1b1818;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    @include xs {
      font-size: 20px;
    }
    span {
      color: #1b1818;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 33.96px; /* 106.124% */
      text-transform: uppercase;
      background: #72c2ff;
      padding: 12px 33px;
      @include md {
        padding: 10px;
        font-size: 26px;
      }
    }
  }
  &__body-error-content{
    display: flex;
    flex-direction: column;

  }
}
