.nav {
  &__list {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__item {
    &:not(:last-child) {
      margin-right: px(20);
    }
  }

  &__link {
    color: var(--baseWhite);
    transition: all 0.3s ease;

    &.active,
    &:hover {
      color: #ca66fb;
    }
  }
}
