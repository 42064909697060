.form {
  .row {
    flex-wrap: wrap;
  }

  .col {
    width: 50%;
    flex: 0 0 50%;
    padding-block: px(15);
    @include media-down(576) {
      width: 100%;
      flex: 0 0 100%;
    }

    &--12 {
      width: 100%;
      flex: 0 0 100%;
    }
  }

  &__agree-privacy {
    display: flex;
    width: fit-content;
    align-items: center;

    .checkbox {
      margin-right: px(10);
    }

    a {
      text-decoration: underline;
      transition: all .3s ease;

      &:hover {
        color: #ca66fb;
      }
    }
  }
}
