.experience {
  background: #fff;
  padding-top: 156px;
  padding-bottom: 160px;
  @include md {
    padding-top: 104px;
    padding-bottom: 104px;
  }
  // .experience__container

  &__container {
    position: relative;
    z-index: 2;
  }

  // .experience__body

  &__body {
    display: flex;
    flex-direction: column;
    gap: 179px;
    @media (max-width: 1024px) {
      gap: 104px;
    }
  }

  // .experience__title

  &__title {
    text-align: center;
    color: #1b1818;
  }

  // .experience__cards

  &__cards {
    display: flex;
    align-items: baseline;
    gap: 24px;
    @media (max-width: 1600px) {
      justify-content: center;
      row-gap: 120px;
    }
    @include md {
    }
    @include xs {
      row-gap: 85px;
     flex-wrap: wrap;
    }
  }

  // .experience__card

  &__card {
    position: relative !important;
    border-radius: 24px;
    background: #1b1818;
    padding: 37px 34px;
    display: flex;
    flex-direction: column;
    min-height: 238px;
    align-items: center;
    max-width: 362px;
    width: 100%;
    @media (max-width: 1600px) {
      max-width: 340px;
    }
    @media (max-width: 1440px) {
      max-width: 300px;
      min-height: 275px;
    }
    @media (max-width: 1300px) {
      max-width: 250px;
      min-height: 255px;
    }
    @media (max-width: 1024px) {
      max-width: 225px;
      min-height: 200px;
    }
    @include sm {
      min-height: 200px;
      max-width: 200px;
      padding: 58px 0;
    }
    @include xs {
      padding: 62px 11px 17px 11px;
      min-height: 200px;
      max-width: 156px;
    }
  }

  // .experience__card-top

  &__card-top {
    border-radius: 24px;
    background: rgba(250, 250, 250, 0.55);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    padding: 32px;
    position: absolute;
    top: -85px;
    img {
      max-width: 64px;
      max-height: 64px;
    }
    @include sm {
      padding: 25px;
      top: -59px;
    }
    @include xs {
      padding: 24px;
      top: -36px;
    }
  }
  &__title-bg {
    font-family: Unbounded;
    font-size: 244px;
    font-style: normal;
    font-weight: 400;
    text-shadow: 1px 1px 0 #1b1818, -1px -1px 0 #1b1818, 1px -1px 0 #1b1818, -1px 1px 0 #1b1818; /* Тень с разными смещениями и цветом */
    color: #fff;
    opacity: 0.2 !important;
    white-space: nowrap;
    text-transform: uppercase;
    position: absolute;

    top: -16.6%;
    left: -8%;
    z-index: -1;

    @media (max-width: 1024px) {
      font-size: 100px;
      top: -7.6%;
      left: 9%;
    }
    @include sm {
      font-size: 80px;
      top: -4%;
      left: 9%;
    }
    @include xs {
      font-size: 70px;
      left: 0%;
    }
  }

  // .experience__subtitle

  &__subtitle {
    color: #fff;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    max-width: 295px;
    text-align: center;
    @media (max-width: 1300px) {
      font-size: 22px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
    }
    @include sm {
      font-size: 14px;
    }
    @include xs {
      font-size: 16px;
    }
  }
  &__card-title {
    color: #72c2ff;
    text-align: center;
    font-family: Unbounded;
    font-size: 60.239px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
    @media (max-width: 1300px) {
      font-size: 50px;
    }
    @media (max-width: 1024px) {
      font-size: 36px;
    }
    @include sm {
      font-size: 25px;
    }
    @include xs {
      font-size: 31px;
    }
  }
}
.container {
}
.title {
}
