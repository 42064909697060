.article {
  font-size: px(16);
  line-height: 1.2;

  & > * {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  hgroup {
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4;
  }

  h1 {
  }
  h2 {
  }
  h3 {
  }
  h4 {
  }
  h5 {
  }
  h6 {
  }

  img {
  }

  picture {
    source {
    }

    img {
    }
  }

  figure {
    img {
    }

    figcaption {
    }
  }

  video {
  }

  iframe {
  }

  li,
  p {
  }

  p {
  }

  blockquote {
  }

  // Lists tags
  ul {
    li {
    }
  }

  ol {
    li {
    }
  }

  hr {
  }

  dl {
  }

  dt {
  }

  dd {
  }

  // Style tags
  time {
  }

  kbd {
  }

  code {
  }

  em,
  i {
  }

  strong,
  b {
  }

  data {
  }

  // Mark tags
  mark {
  }

  del {
  }

  ins {
  }

  // Table
  table {
    caption {
    }

    colgroup {
    }

    col {
    }

    th,
    td {
    }

    tr {
    }

    thead {
    }

    th {
    }

    tbody {
    }

    td {
    }
  }
}
