.faq {
  padding-top: 156px;
  padding-bottom: 200px;
  background: #1b1818;
  @include md {
    padding-top: 104px;
    padding-bottom: 104px;
  }
  &__title-bg {
    top: -150px;
    left: 5%;
    @media (max-width: 1024px) {
      font-size: 100px;
      top: -44px;
      left: 3%;
    }

    @include xs {
      left: 2%;
      font-size: 70px;
    }
  }
  // .faq__container

  &__container {
    max-width: 1020px;
    position: relative;
    z-index: 2;
  }

  // .faq__body

  &__body {
    display: flex;
    flex-direction: column;
    gap: 51px;
  }

  // .faq__title

  &__title {
  }

  // .faq__bottom

  &__bottom {
  }
}
.container {
}

.asked {
  // .asked__list

  &__list {
    display: flex;
    flex-direction: column;
    gap: 46px;
    width: 100%;
  }

  // .asked__list-item

  &__list-item {
    width: 100%;

    transition: all 0.4s;
    span {
      transition: all 0.4s;
    }
    &:hover {
      .asked__list-title-text {
        color: rgba(114, 194, 255, 1);
      }
      .asked__list-title {
        span {
          svg {
            use {
              stroke: rgba(114, 194, 255, 1);
            }
          }
        }
      }
    }
    &._active {
      gap: 24px;
      //   display: flex;
      span {
        transform: rotate(-180deg);
      }

      .asked__show {
        max-height: 1000px;
        overflow: visible;
        visibility: visible;
        opacity: 1;
        padding: 24px 0 16px 0;
        @include sm {
          max-height: 200px;
          padding: 15px 0 0px 0;
        }
      }
    }
  }

  // .asked__list-title

  &__list-title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    span {
      svg {
        use {
          transition: all 0.4s;
        }
      }
    }
    @include xs {
      span {
        svg {
          width: 20px;
        }
      }
    }
  }
  &__list-title-text {
    color: #fff;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    text-transform: uppercase;
    max-width: 877px;
    transition: all 0.4s;

    @include md {
      font-size: 26px;
      max-width: 700px;
    }
    @include sm {
      max-width: 500px;
      font-size: 24px;
    }
    @include xs {
      font-size: 18px;
      max-width: 300px;
    }
  }

  // .asked__show

  &__show {
    color: rgba(255, 255, 255, 0.78);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    max-width: 748px;
    @media (max-width: 1024px) {
      max-width: unset;
    }
    a {
      color: rgba(255, 255, 255, 0.78);
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 30px */
      transition: all 0.4s;
      @include xs {
        font-size: 14px;
      }
      &:hover {
        color: #72c2ff;
      }
    }
    @include xs {
      font-size: 14px;
    }

    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.4s;
    padding: 0;
  }
}
