.start {
  padding-top: 200px;
  padding-bottom: 164px;
  background: #1b1818;
  @media (max-width: 1024px) {
    padding-top: 104px;
    padding-bottom: 104px;
  }
  @include xs {
    padding-top: 49px;
  }
  // .start__container

  &__container {
    max-width: 1485px;
    position: relative;
    z-index: 2;
  }

  &__title-bg {
    top: -10%;
    left: -40%;
    z-index: -1;
    @media (max-width: 1024px) {
      font-size: 100px;
      top: -5%;
      left: 0;
    }

    @include xs {
      top: 1%;
      font-size: 70px;
    }
  }

  // .start__body

  &__body {
    display: flex;
    flex-direction: column;
    gap: 68px;
  }

  // .start__top

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // .start__subtitle

  &__subtitle {
    text-align: center;
    color: #fff;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 42px */
    @include xs {
      display: none;
    }
  }

  // .start__title

  &__title {
    text-align: center;
    white-space: nowrap;
    font-size: 72px;
    @include md {
      font-size: 70px;
    }
    @include sm {
      font-size: 56px;
    }
    @include xs {
      font-size: 36px;
    }
    @media (max-width: 1700px) {
      white-space: unset;
    }
  }

  // .start__bottom

  &__bottom {
  }

  // .start__form

  &__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
  }

  &__inputs-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: center;
    @include md {
      flex-direction: column;
    }
  }

  // .start__wrapper

  &__wrapper {
    min-height: 81px;
    position: relative;

    &.invalid {
      .start__error {
        display: block;
      }
    }

    @include md {
      width: 100%;
    }
  }

  .label {
    color: #1b1818;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    position: absolute;
    top: 39%;
    left: 24px;
    transform: translate(0, -50%);
    pointer-events: none;

    span {
      color: #ff3b3b;
    }

    @include xs {
      font-size: 14px;
      font-weight: 400;
    }
  }

  // .start__input

  &__input {
    padding: 16px 24px;
    width: 500px;
    cursor: pointer;
    border: 2px solid transparent;

    &.error {
      border: 2px solid #ff3b3b;
    }

    color: #1b1818;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    transition: all 0.4s;

    &:hover {
      border: 2px solid #72c2ff;
    }

    &:focus {
      border: 2px solid #72c2ff;
    }

    @include md {
      width: 100%;
    }
  }

  &__error {
    color: #ff5b5b;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    position: absolute;
    top: -20px;
    left: 0;
    display: none;

    &.active-error {
      display: block;
    }
  }

  // .start__inputs

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include md {
      width: 100%;
    }
  }

  // .start__form-btn

  &__form-btn {
    padding: 30px 70px;
    margin-top: 166px;
    position: relative;

    &::after {
      content: '';
      height: 56px;
      width: 1px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      top: -150%;
      @include xs {
        top: -130%;
      }
    }

    @include xs {
      margin-top: 80px;
      padding: 16px 26px;
      font-size: 16px;
    }
  }

  &__textarea {
    width: 1005px;
    height: 144px;
    color: #1b1818;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 16px 24px;
    cursor: pointer;

    @include md {
      width: 100%;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  width: 500px;
  @include md {
    width: 100%;
  }
}

.dropdown-btn {
  padding: 10px;
  background: #fff;
  border: 3px solid #ddd;
  cursor: pointer;
  width: 100%;
  padding: 16px 24px;
  color: #1b1818;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .required{
    color: #ff3b3b;
  }

  &:hover {
    border: 3px solid rgba(114, 194, 255, 0.39);
  }

  svg {
    transition: all 0.4s;
  }
}

.dropdown-btn.open svg {
  transform: rotate(-180deg);
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10000;
  width: 100%;

  li {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
  }

  label {
    cursor: pointer;
    color: rgba(27, 24, 24, 0.56);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    display: block;
    line-height: 150%; /* 21px */
  }

  &.show-drop {
    display: block;
  }
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.dropdown-menu input[type='checkbox'] {
  margin-right: 10px;
}

.dropdown.show-drop .dropdown-menu {
  display: block;
}

/* Стили для чекбокса и его галочки */
input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(206, 206, 206, 1);
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type='checkbox']::after {
  content: '\2713'; /* Юникод символ галочки */
  position: absolute;

  top: -1px;
  left: 3px;

  font-size: 14px;
  color: #007bff;
  visibility: hidden;
}

/* При нажатии на чекбокс показываем галочку */
input[type='checkbox']:checked::after {
  visibility: visible;
}

[data-aos^='fade'][data-aos^='fade'].aos-animate {
  z-index: 1;
}

.label-form {
  &._active {
    color: #1b1818;
  }
}
