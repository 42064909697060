.about {
  padding-top: 156px;
  padding-bottom: 199px;
  background-color: #fff;
  min-height: 1133px;
  position: relative;
  z-index: 2;
  @media (max-width: 1024px) {
    min-height: 910px;
  }
  @include md {
    min-height: unset;
  }
  @include md {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  // .about__container

  &__container {
    max-width: 1950px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }
  &__title-bg {
    left: 10%;
    @media (max-width: 1024px) {
      font-size: 132px;
      left: 5%;
      top: -100px;
    }
    @include xs {
      left: 1%;
      top: -30px;
      font-size: 70px;
    }
  }

  // .about__body

  &__body {
    display: flex;
    flex-direction: column;
    gap: 109px;
    position: relative;
    @media (max-width: 1440px) {
      gap: 20px;
    }
    @include md {
      gap: 144px;
    }
    @include xs {
      gap: 110px;
    }
  }

  // .about__title

  &__title {
    color: #1b1818;
    padding-left: 200px;
    @media (max-width: 1750px) {
      padding-left: 150px;
    }
    @media (max-width: 1440px) {
      padding-left: 50px;
    }
    @include sm {
      padding-left: 24px;
    }
  }

  // .about__bottom

  &__bottom {
    min-height: 650px;
    @media (max-width: 1024px) {
      min-height: 570px;
    }
    @include md {
      min-height: unset;
      position: relative;
      padding-top: 100px;
    }
  }

  // .about__flex

  &__flex {
    display: flex;
    align-items: center;
    margin-bottom: 86px;
    margin-top: 86px;
    @include md {
      margin-top: unset;
      margin-bottom: 41px;
    }
  }
  &__flex-text {
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    max-width: 740px;
    mix-blend-mode: difference;
    max-height: 220px;
    overflow-y: auto;
    @media (max-width: 1024px) {
      font-size: 16px;
      overflow-y: unset;
    }
    @include xs {
      font-size: 16px;
      max-height: 350px;
    }
  }

  // .about__text

  &__text {
    border-radius: 24px;
    padding: 59px;
    margin: 0 auto;
    background: rgba(250, 250, 250, 0.55);
    backdrop-filter: blur(18.5px);
    border: 1px solid gray;
    @media (max-width: 1024px) {
      max-width: 542px;
      padding: 32px;
      min-height: 235px;
      display: flex;
      align-items: center;
    }
    @include md {
      max-width: unset;
      width: 90%;
    }
    @include xs {
      padding: 24px;
      min-height: 355px;
    }
  }

  &__img1 {
    position: absolute;
    left: 0;
    bottom: 111px;
    z-index: -1 !important;
    max-width: 629px;
    width: 100%;
    @media (max-width: 1750px) {
      max-width: 500px;
    }
    @media (max-width: 1550px) {
      max-width: 400px;
    }
    @media (max-width: 1230px) {
      max-width: 340px;
    }
    @media (max-width: 1024px) {
      max-width: 288px;
      bottom: 230px;
    }

    img {
      width: 100%;
      height: 627px;
      object-fit: cover;
      @media (max-width: 1550px) {
        height: 500px;
      }
      @media (max-width: 1024px) {
        height: 374px;
      }
    }
    @include md {
      position: absolute;
      top: -70px;
      width: 100%;
      max-width: unset;
      img {
        width: 100%;
        height: 221px;
      }
    }
    @include xs {
      top: -15px;
      height: 221px;
    }
  }

  // &__swiper-slide {
  //   @include md {
  //     padding-top: 100px;
  //   }
  // }

  &__img2 {
    position: absolute;
    right: 0;
    bottom: 256px;
    z-index: -1 !important;
    max-width: 433px;

    @media (max-width: 1550px) {
      max-width: 300px;
    }
    @media (max-width: 1024px) {
      max-width: 214px;
      bottom: 310px;
    }

    img {
      width: 100%;
      height: 625px;
      object-fit: cover;
      @media (max-width: 1550px) {
        height: 425px;
      }
      @media (max-width: 1024px) {
        height: 374px;
      }
    }
    @include md {
      display: none;
    }
  }

  // .about__btn

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: -244px;
    z-index: 100;
    gap: 50px;
    @include md {
      margin-top: unset;
    }
    @media (max-width: 1400px) {
      gap: 30px;
    }
    svg {
      @media (max-width: 1400px) {
        width: 80px;
      }
      @media (max-width: 1024px) {
        width: 52px;
      }
    }
  }

  &__arrow-button-prev,
  &__arrow-button-next {
    &:focus-within {
      box-shadow: unset;
    }
    svg {
      path {
        transition: all 0.4s;
      }
    }
    &:hover {
      svg {
        path {
          fill: rgba(114, 194, 255, 1);
        }
      }
    }

    @include md {
      display: none;
    }
  }

  // .about__button

  &__button {
    background: #1b1818;
    padding: 30px 100px;
    color: #fff;
    @media (max-width: 1440px) {
      padding: 16px 75px;
    }
    @include xs {
      padding: 16px 40px;
      font-size: 16px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1px;
        height: 32px;
        background-color: #1b1818;
        z-index: 10;
      }
    }
  }

  &__swiper {
    width: 100%;
    overflow-y: unset;
  }
}

.swiper-pagination {
  // background-color: red;
  width: 100%;
  height: 3px;
  background: #d9d9d9;
  margin-bottom: 81px;
  display: none;
  @include md {
    display: block;
  }
}
.swiper-scrollbar-drag {
  width: 90.75px;
  background: #1b1818;
  height: 3px;
}

.swiper-button-disabled {
  &:hover {
    svg {
      path {
        fill: #1b1818;
      }
    }
  }
  svg {
    path {
      opacity: 0.1;
      pointer-events: none;
    }
  }
}

.about__swiper-slide.swiper-slide-active {
  .about__img1,
  .about__img2 {
    opacity: 0;
    animation: zoomOutUp 1s forwards;
    transform-origin: center bottom;
  }
}

@keyframes zoomOutUp {
  0% {
    transform: scale(0.5) translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
