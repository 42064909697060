.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: px(20);
  min-width: px(20);
  height: px(20);
  position: relative;
  border: px(1) solid #000;
  transition: all .3s ease;
  cursor: pointer;

  &:hover {
    .checkbox__custom {
      opacity: 0.2;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    cursor: pointer;
  }

  &__custom {
    width: 100%;
    height: 100%;
    transition: all .3s ease;
    border: px(1) solid #fff;
    pointer-events: none;
    background-color: #000;
    opacity: 0;
  }

  &__input:checked + &__custom {
    opacity: 1;
  }
}
