.testimonials {
  padding-top: 156px;
  padding-bottom: 228px;
  background-color: #fff;
  @include md {
    padding-top: 104px;
    padding-bottom: 104px;
  }
  // .testimonials__container

  &__container {
    position: relative;
    z-index: 2;
  }
  &__title-bg {
    left: -20%;
    @media (max-width: 1024px) {
      font-size: 100px;
      top: -50px;
      left: 5%;
    }
    @include xs {
      top: -30px;
      font-size: 70px;
      left: -2%;
    }
  }

  // .testimonials__body

  &__body {
    display: flex;
    flex-direction: column;
    gap: 136px;
    @include sm {
      gap: 34px;
    }
  }

  // .testimonials__top

  &__top {
  }

  // .testimonials__title

  &__title {
    text-align: center;
    color: #1b1818;
    position: relative;
  }

  // .testimonials__bottom

  &__bottom {
    padding: 0 15px;

    @include xs {
      padding: 0;
    }
  }

  // .testimonials__blocks

  &__blocks {
    display: flex;
    gap: 86px;
    justify-content: center;
    @media (max-width: 1300px) {
      gap: 50px;
    }
    @include md {
      flex-direction: column;
    }
    @include xs {
      gap: 30px;
      transition: all 0.4s;
      &._active {
        gap: 84px;
      }
    }
  }

  // .testimonials__left

  &__left {
    @include xs {
      padding-left: 10px;
    }
  }
  &__right {
    @include xs {
      // padding-right: 32px;
    }
  }
  &__item-top {
    min-height: 134px;
    margin: 0 auto;
    img {
      max-width: 300px;
      @include sm {
        max-width: 261px;
        max-height: 102px;
      }
    }
    @include sm {
      min-height: 105px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @include sm {
      gap: 25px;
    }
    @include md {
      &:nth-child(2),
      &:nth-child(3) {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        pointer-events: none;
        transition: all 0.4s;
      }
      &._active {
        &:nth-child(2),
        &:nth-child(3) {
          opacity: 1;
          max-height: 500px;
          overflow: visible;
          pointer-events: unset;
        }
      }
    }
  }
  &__bottom-show {
    display: none;
    @include md {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      background: #000;
      padding: 12px 40px;
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      gap: 16px;
      transition: all 0.4s;
      opacity: 1;
      overflow: visible;
      visibility: visible;
      pointer-events: unset;
      &.hide {
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
        pointer-events: none;
      }
    }
  }
  &__item-name {
    position: static !important;
    color: #1b1818;
    font-family: Montserrat;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
    padding-left: 80px;
    position: relative !important;
    display: block;
    top: 24px !important;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 63px;
      height: 1px;
      background-color: #000;
    }
  }

  &__item-bottom {
    position: relative;
    @include md {
      width: fit-content;
      margin: 0 auto;
    }
    p {
      color: #1b1818;
      font-family: Montserrat;
      font-size: 20px;
      font-style: italic;
      font-weight: 400;
      line-height: 150%;
      max-width: 555px;
      min-width: 300px;
      max-height: 540px;
      overflow-y: auto;
      @include xs {
        font-size: 16px;
        min-width: unset;
        max-width: 319px;
        max-height: 250px;

      }
    }
    span {
      position: absolute;
      top: -25px;
      left: -10px;
      @include xs {
        left: 4px;
      }
    }
  }
}
