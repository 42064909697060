// Nav
@import 'nav';

// Header
@import 'header';

// Footer
@import 'footer';

// Section
@import 'sections';

@import './main-page/hero.scss';

@import './main-page/do.scss';

@import './main-page/experience.scss';

@import './main-page/faq.scss';

@import './main-page/about.scss';

@import './main-page/form.scss';

@import './main-page/testimonials.scss';

@import './privacy/privacy.scss';

@import 'icons';
section {
  overflow: hidden;
}

.error-page {
  background: #1b1818;
  &__container {
    height: 100vh;
    position: relative;
  }
  &__title-bg {
    font-size: 452.83px;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-shadow: 1px 1px 0 #72c2ff, -1px -1px 0 #72c2ff, 1px -1px 0 #72c2ff, -1px 1px 0 #72c2ff; /* Тень с разными смещениями и цветом */
    @include sm {
      font-size: 200px;
    }
  }
  &__body {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    h1 {
      color: #72c2ff;
      text-align: center;
      font-family: Unbounded;
      font-size: 180px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      @include sm {
        font-size: 100px;
      }
    }
    h3 {
      color: var(--white, #fbfbfb);
      text-align: center;
      font-family: Montserrat;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 150% */
      text-transform: uppercase;
    }
    p {
      color: var(--white, #fbfbfb);
      text-align: center;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
    }
  }
}
