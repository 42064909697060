.footer {
  padding-top: 58px;
  padding-bottom: 64px;
  @include xs {
    padding-bottom: 24px;
  }
  // .footer__container

  &__container {
    @media (max-width: 1750px) {
      padding: 0 65px;
    }
    @media (max-width: 1600px) {
      padding: 0 120px;
    }
    @media (max-width: 1250px) {
      padding: 0 15px;
    }
  }

  // .footer__body

  &__body {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
  }

  // .footer__left

  &__left {
    @media (max-width: 1024px) {
      margin-bottom: 44px;
    }
  }

  // .footer__column

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    @media (max-width: 1024px) {
      min-height: unset;
      align-items: center;
    }
  }

  &__copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -20px;
    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 48px;
    }
  }
  // .footer__copy

  &__copy {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
  }

  // .footer__center

  &__center {
    @media (max-width: 1024px) {
      margin-bottom: 48px;
    }
  }

  // .footer__menu

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media (max-width: 1024px) {
      gap: 16px;
    }
  }

  // .footer__menu-list

  &__menu-list {
    text-align: center;
  }

  // .footer__menu-link

  &__menu-link {
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
    text-transform: uppercase;
    &:hover {
      color: #5bbaff;
    }
  }

  // .footer__right

  &__right {
    @media (max-width: 1024px) {
      min-height: unset;
      align-items: center;
    }
  }
  &__social {
    display: flex;
    gap: 22px;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 1024px) {
      align-items: center;
      margin-bottom: 48px;
      gap: 16px;
    }
  }
  &__social-soc {
    display: flex;
    align-items: center;
    gap: 22px;
    &:nth-child(1) {
      text-transform: uppercase;
    }
    &:nth-child(2) {
      text-transform: uppercase;
    }
  }
  &__menu-flex {
    display: flex;
    align-items: baseline;
    gap: 84px;
    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 16px;
    }
  }
  &__copy-mob {
    display: none;
    @media (max-width: 1024px) {
      display: block;
      color: #fff;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;
    }
  }

  // .footer__social

  &__social-link {
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 21.6px */
    text-align: right;
    &:hover {
      color: #5bbaff;
    }

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  // .footer__privacy

  &__privacy {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    text-decoration-line: underline;
    text-align: right;
    &:hover {
      color: #5bbaff;
    }
    @media (max-width: 1024px) {
      margin-bottom: 16px;
    }
  }
}
