.faq__arrow-down {
  width: 54px;
  height: 54px;
  use {
    stroke: #fff;
    font-weight: 700;
    stroke-width: 2px;
    fill: none;
    @include xs {
      stroke-width: 4px;
    }
  }
}

.square {
  stroke-width: 13px;
  stroke: #72c2ff;
  width: 13px;
  height: 13px;
}

.exp {
  width: 64px;
  height: 64px;
  @include sm {
    width: 48px;
    height: 48px;
  }
  @include xs {
    
  }
}
.arrow-small {
  width: 18px;
  height: 9px;
  use {
    stroke: #1b1818;
    stroke-width: 3px;
    font-weight: 700;

    fill: none;
    @include xs {
      stroke-width: 4px;
    }
  }
}

.show-arrow {
  width: 18px;
  height: 8px;
  use {
    stroke-width: 7px;
  }
}

.whatsupp,
.insta {
  width: 24px;
  height: 24px;
  fill: #fff;
  @include md {
    width: 40px;
    height: 40px;
  }
  &:hover {
    fill: #72c2ff;
  }
}
