.header {
  min-height: 100px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  // .header__container

  &__container {
    width: 100vw;
    padding: 0 200px;
    position: relative;
    @media (max-width: 1440px) {
      padding: 0 15px;
    }
  }

  // .header__body

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // .header__logo

  &__logo {
    a {
      color: #fff;
      font-size: 48px;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
      @include md {
        position: relative;
        z-index: 15;
      }
    }
  }
}

.menu {
  background: rgba(27, 24, 24, 0.39);
  backdrop-filter: blur(50px);
  position: absolute;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  right: 0;
  top: -100px;
  min-height: 1077px;
  max-width: 948px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  @media (max-width: 1440px) {
    max-width: 600px;
    min-height: 800px;
  }
  @media (max-width: 1024px) {
    max-width: 400px;

  }
  @include md {
    width: 100%;
    height: 100%;
    min-height: unset;
    max-width: unset;
    position: fixed;
    top: 0;
    background-color: black;
  }
  &.active {
    opacity: 1;
    overflow: visible;
    visibility: visible;
    overflow-y: auto;
    pointer-events: unset;
  }
  // .menu__list

  &__list {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    padding-top: 145px;
    padding-bottom: 80px;
    @include md {
      padding-top: unset;
      padding-bottom: unset;
    }
  }

  // .menu__list-item

  &__list-item {
  }

  // .menu__list-link

  &__list-link {
    color: #fff;
    font-family: Unbounded;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
    transition: all 0.4s;
    @media (max-width: 1550px) {
      font-size: 45px;
    }
    @media (max-width: 1440px) {
      font-size: 35px;
    }
    @media (max-width: 1024px) {
      font-size: 28px;
    }

    @include xs {
      font-size: 30px;
    }
    &:hover {
      color: #72c2ff;
    }
  }
}

// .icon-menu {
//   display: block;
//   position: relative;
//   width: 43px;
//   height: 20px;
//   cursor: pointer;
//   z-index: 10000;

//   span {
//     transition: all 0.3s ease 0s;
//     top: calc(50% + 1px);
//     left: 0px;
//     position: absolute;
//     width: 100%;
//     height: 4px;
//     background-color: #fff;

//     &:first-child {
//       top: 0px;
//     }

//     &:last-child {
//       top: auto;

//       bottom: -1px;
//     }
//   }

//   &._active {
//     span {
//       transform: scale(0);

//       &:first-child {
//         transform: rotate(-45deg);
//         top: calc(50% - 3px);
//       }

//       &:last-child {
//         transform: rotate(45deg);
//         bottom: calc(50% - 1px);
//       }
//     }
//   }
// }

.icon-menu {
  display: block;
  position: relative;
  width: 43px;
  height: 32px;
  cursor: pointer;
  z-index: 2000;
  @media (min-width: 991px) {
    &:hover {
      span,
      &::before,
      &::after {
        background-color: #72c2ff;
      }
    }
  }

  span,
  &::before,
  &::after {
    content: '';
    transition: all 300ms ease 0ms;
    right: 0;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
  }
  &::before {
    top: 1px;
    width: 100%;
  }
  &::after {
    bottom: 0;
    width: 100%;
  }
  span {
    top: calc(50% - 1px);
  }
  &._active {
    &::before,
    &::after {
      opacity: 0;
    }
    span {
      transform: scale(0);

      &:first-child {
        transform: rotate(-45deg);
        top: calc(50% - 3px);
      }

      &:last-child {
        transform: rotate(45deg);
        top: calc(50% - 3px);
      }
    }
  }
}
