.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 100;

  // .hero__bg

  &__bg {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      &:nth-child(1) {
        @include md {
          display: none;
        }
      }
    }
    img {
      display: none;
      @include md {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  // .hero__container

  &__container {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // z-index: 20;
  }

  // .hero__body

  &__body {
    display: flex;
    flex-direction: column;
    gap: 160px;
    align-items: center;
    @media (max-width: 1024px) {
      gap: 80px;
    }

    @include xs {
      padding-top: 178px;
      padding-bottom: 100px;
    }
  }

  // .hero__text

  &__text {
  }

  // .hero__title

  &__title {
    color: #fff;
    font-size: 88px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 105.6px */
    text-transform: uppercase;
    text-align: center;
    span {
      font-size: 44px;
      line-height: 120%;

      @include xs {
        font-size: 34px;
      }
    }
    @media (max-width: 1024px) {
      font-size: 60px;
    }

    @include sm {
      font-size: 56px;
    }
    @include xs {
      max-width: 236px;
      margin: 0 auto;
    }
  }

  // .hero__subtitle

  &__subtitle {
    color: #fff;
    font-size: 40px;
    font-weight: 400;
    line-height: 150%;
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 30px;
    }

    @include sm {
      font-size: 25.6px;
    }
    @include xs {
      max-width: 340px;
      margin: 0 auto;
    }
  }

  // .hero__button

  &__button {
    width: 264px;
    margin: 0 auto;
    min-height: 69px;
    padding: 21px 72px;
  }
}

.whatsapp {
  position: fixed;
  bottom: 64px;
  right: 64px;
  z-index: 10000;
  transform: translate(0, 0);
  animation: bellshake 3s ease infinite;
  svg {
    transition: all 0.4s;
  }
  &:hover {
    svg {
      transform: translateY(-5px);
    }
  }
  @media (max-width: 1750px) {
    right: 10px;
  }
  @media (max-width: 1600px) {
    svg {
      width: 80px;
    }
  }
  @include md {
    right: 10px;
    bottom: 10px;
    svg {
      width: 50px;
    }
  }
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}
